import React from 'react';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      <Helmet>
        <title>Why It Matters</title>
      </Helmet>
      <nav style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          padding: '10px 20px', 
          marginTop: '3%', 
          boxShadow: 'none' 
        }}>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <div style={{ marginRight: '15px', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500' }}>Resources</div>
          <div style={{ marginRight: '15px', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500' }}>Blog</div>
          <div style={{ marginRight: '15px', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500' }}>Contact</div>
        </div>
        <div style={{ cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500' }}>
          &#9776; {/* Hamburger menu icon */}
        </div>
      </nav>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 60px)' }}>
        <div style={{ marginRight: '20px', textAlign: 'left', paddingBottom: '20px' }}>
          <h1 style={{ lineHeight: '1.2', fontSize: '48px', fontFamily: 'Montserrat, sans-serif', fontWeight: '700', textAlign: 'left' }}>
            Why<br />It<br />Matters
          </h1>
        </div>
        <input 
          type="text" 
          placeholder="Enter your text here" 
          style={{ padding: '10px', fontSize: '24px', height: '150px', fontFamily: 'Montserrat, sans-serif' }} 
        />
      </div>
    </>
  );
}

export default App;